import * as React from 'react'
import { Header, Container, Segment, Icon, Image } from 'semantic-ui-react'
import { withLayout } from '../components/Layout'
import { Grid } from 'semantic-ui-react'
import FormModalButton from '../components/Form/Form'
const phoneImg = require('../../assets/phone.jpeg')

const ContactPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="phone" />
          <Header.Content>Contact Us</Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={10}>
              <Header>Get In Touch</Header>
              <p>
                Elite Concrete operates from Monday to Saturday during regular
                business hours. For your convenience, we also offer quotes via
                phone outside these hours. Feel free to reach out to us by
                telephone or email to discuss any projects you're looking to
                have completed.
              </p>

              <p>
                <Icon name="phone" />
                <a
                  style={{ fontFamily: 'Oswald', color: '#141a26' }}
                  href="tel:678-724-7984"
                >
                  678-724-7984
                </a>
              </p>
              <p>
                <Icon name="envelope" />
                georgiaeliteconcrete@gmail.com
              </p>
              <p>
                <FormModalButton />
              </p>
            </Grid.Column>
            <Grid.Column width={6}>
              <Image src={phoneImg} alt="image" rounded={true} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  )
}

export default withLayout(ContactPage)
